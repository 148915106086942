
import { Component, Vue } from 'vue-property-decorator';
import { getAllUser, updateUserFrozen } from '@/api/user';


type IUser = IResponse.User.IUser;


const columns = [
  {
    title: 'Name',
    dataIndex: 'username',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Frozen',
    dataIndex: 'isFrozen',
    scopedSlots: { customRender: "isFrozen" },
    width: 300
  },
]

@Component({})
export default class UserManagement extends Vue {
  public name: string = 'UserManagement';
  public dataSource: IUser[] = [];
  public columns: object[] = columns;
  public created(): void {
    this.initData();
  }
  public async initData() {
    const response = await getAllUser();
    if (response.status) {
      this.dataSource = response.data;
    } else {
      this.$message.error(response.message)
      this.dataSource = [];
    }
    
  }
  public async updateFrozen(user: IUser) {
    const response = await updateUserFrozen(user.id)
    if (response.status) {
      this.$message.success('success');
      user.isFrozen = !user.isFrozen;
    } else {
      this.$message.error(response.message);
    }
  }
}
